<template>
  <v-card outlined height="100%">
    <card-toolbar
      :graphic="graphic"
      :title="graphic.title"
      :helpId="17"
      @showFilters="filtersDialog = true"
      @deleteGraphic="$emit('deleteGraphic', $event)"
    />

    <div class="d-flex align-items-center" style="height: 70%">
      <v-row align="center" class="mx-0 px-2">
        <h2 class="d-flex text-h3 mr-4">
          {{ metric.data[0] ? metric.data[0][1] : 0 }}

          <span class="mx-2 my-auto" style="font-size: 14px">{{
            metric.visualData
          }}</span>
        </h2>
      </v-row>
      <span style="font-size: 10px; position: absolute; right: 5px; bottom: 5px"
        >{{
          new Date(startAt).toLocaleDateString('es-VE', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            timeZone: 'UTC',
          })
        }}
        -
        {{
          new Date(endAt).toLocaleDateString('es-VE', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            timeZone: 'UTC',
          })
        }}</span
      >
    </div>
    <v-dialog
      v-model="filtersDialog"
      width="auto"
      :max-width="$vuetify.breakpoint.xs ? '90%' : '50%'"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Filtrar </v-card-title>

        <date-filter
          :startAt.sync="startAt"
          :endAt.sync="endAt"
          :periodicity="periodicity"
          :validDate.sync="validDate"
        />

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            :disabled="!validFilter"
            @click.prevent="getMetricData(), (filtersDialog = false)"
          >
            Aplicar filtros
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  import CardToolbar from '@/components/Dashboards/CardToolbar'
  import DateFilter from '@/components/Dashboards/Filters/DateFilter'
  import { decryptData } from '@/utils/encryption'
  import { itemsPeriodicity, toLocaleDateString } from '@/utils/helpers'
  import { getIntervalDatesByPeriodicity } from '@/utils/dateFunctions'
  import { handleMetricText } from '@/utils/metricHelpers'
  const today = new Date()
  export default {
    components: {
      CardToolbar,
      DateFilter,
    },
    props: {
      graphic: {
        type: Object,
        required: true,
        default: () => {},
      },
      generalStartAt: {
        type: String,
        required: true,
      },
      generalEndAt: {
        type: String,
        required: true,
      },
      generalPeriodicity: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        index: 0,
        validDate: true,
        filtersDialog: false,
        metric: { data: [null, 0], visualData: '' },
        itemsPeriodicity: itemsPeriodicity,
        periodicity: 3,
        startAt: new Date(today.getFullYear(), today.getMonth() - 1, 1)
          .toISOString()
          .split('T')[0],
        endAt: new Date(today.getFullYear(), today.getMonth(), 0)
          .toISOString()
          .split('T')[0],
      }
    },
    created() {
      this.periodicity = this.generalPeriodicity
      this.startAt = this.generalStartAt
      this.endAt = this.generalEndAt
      this.getMetricData()
    },
    computed: {
      ...mapGetters(['userCurrentCompany']),
      company: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set(newValue) {},
      },
      dateString() {
        return `&startAt=${this.startAt}&endAt=${this.endAt}`
      },
      validFilter() {
        return this.validDate
      },
      companyString() {
        return `&company_id=${this.company.id}`
      },
    },
    watch: {
      periodicity(newValue) {
        const interval = this.getIntervalDatesByPeriodicity(today, newValue)
        this.startAt = interval.startAt
        this.endAt = interval.endAt
        this.getMetricData()
      },
      generalPeriodicity(newValue) {
        this.periodicity = newValue
      },
      generalStartAt(newValue) {
        this.startAt = newValue
      },
      generalEndAt(newValue) {
        this.endAt = newValue
      },
    },
    methods: {
      handleMetricText: handleMetricText,
      toLocaleDateString: toLocaleDateString,
      getIntervalDatesByPeriodicity: getIntervalDatesByPeriodicity,

      getMetricData() {
        this.$axios
          .get(this.graphic.endpoint + this.dateString + this.companyString)
          .then((response) => {
            this.metric.data = Object.entries(response.data)
            this.metric.visualData = this.handleMetricText(this.metric.data[0])
            this.index++
          })
      },
    },
  }
</script>

<style lang="scss" scoped></style>
